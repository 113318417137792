import logo from './nedo-logo.jpeg';
import './App.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Card({ title, text }) {
  return (
    <div className="Card">
      <h2 className="Card-title">{title}</h2>
      <p className="Card-text">{text}</p>
    </div>
  );
}

function App() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo-n" alt="logo" />
        <Card title="Welcome" text="Hello Ana, this is my new hobby" />
      </header>
      <main className="App-content">
        <Slider {...settings}>
          <Card title="Need a simple website?" text="This will be clickable. For all of those that want to buy a finished website with hosting and all." />
          <Card title="Need help with hosting?" text="You have a simple website, and need it accessible?" />
          <Card title="Need performance testing?" text="If I have a server I can also DDOS your page and check how it works" />
          <Card title="Need (maybe some devops solutions)?" text="I work on that" />
          <Card title="About me" text="Full presentation on me, CV style: <link-incoming>" />
        </Slider>
        <br></br>
        <div className="Additional-content">
          <h2>More Content Below</h2>
          <p>There will be some additional content here, like blog posts.</p>
          {/* Add more cards or components as needed */}
          <Card title="How to easily deploy new app" text="Yeah right, like I would tell you" />
          <Card title="How about hosting?" text="Nope, still nothing" />
          {/* Add as many cards as needed */}
        </div>
      </main>
      <footer className="App-footer">
          <Card text="PS: I love you :)" />
      </footer>
    </div>
  );
}

export default App;
